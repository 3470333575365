import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
import Checkurl from './checkurl';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>

    <h1>{`Convertir un sondage Google Forms en rapport visuel intelligent`}</h1>
    <p>{`Vous avez publié un formulaire `}<a parentName="p" {...{
        "href": "https://www.google.fr/intl/fr/forms/about/"
      }}>{`Google Forms`}</a>{` et avez des réponses que vous voulez analyser et présenter. Google Forms offre un service minimum sur l'onglet `}<strong parentName="p">{`Réponses`}</strong>{`.  `}</p>
    <p>{`Si vous voulez mieux et plus conforme à l'état de l'art de la visualisation de données, alors 3 étapes faciles.`}</p>
    <h2>{`1- Convertir les réponses du sondage en Google sheets`}</h2>
    <p>{`Au sommet de la page se trouve deux onglets: `}<strong parentName="p">{`Questions`}</strong>{` et `}<strong parentName="p">{`Réponses`}</strong>{`. Quand ce deuxième est sélectionné apparait une petite croix blanche sur fond vert. C'est l'appui sur ce bouton qui provoque la conversion et l'ouverture du classeur. Il suffit de le faire une fois. Si des nouvelles réponses arrivent elles sont automatiquement ajoutées en bas du classeur.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.65030674846626%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABEElEQVQoz5VSTU+EMBDt//8dHj0bD3oyMcY9eDIxouIuixiWhcKWQj+fbQFdBU2c5HXaznRmXmeIMQZaa3htLRzcgkEraTCT0Y6WH19+7sgm2SJ+XSPPc7BaIr6vkD43WD9QrC7egpPRFtZMMOG5LUrYJIX2+8nmkpFD3cGjZT2KrMXmkSJ5coi8rtEeBDg7gj93GjwtIE7PgKIF5+7MZLCTn0zmDO13+Eq8wVUorm+xuruCyN4hxyrJwN8uP17IEu7HP+yzDJfRDcRLDDk0AGQpyG+YiasS2R6Qakhi7Rflv2QK1guBfVmCUoqmabCrKnRGIt9ynJ9EQ5f/E9CPlpQyQCkF0Qs3ciqMF6tF8PkAD1Rw+22VwgMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "conversion en Google sheet",
          "title": "conversion en Google sheet",
          "src": "/static/b2f83baf0bc407f6ffe521af9f133465/a6d36/GoogleForms-0-05.png",
          "srcSet": ["/static/b2f83baf0bc407f6ffe521af9f133465/222b7/GoogleForms-0-05.png 163w", "/static/b2f83baf0bc407f6ffe521af9f133465/ff46a/GoogleForms-0-05.png 325w", "/static/b2f83baf0bc407f6ffe521af9f133465/a6d36/GoogleForms-0-05.png 650w", "/static/b2f83baf0bc407f6ffe521af9f133465/e548f/GoogleForms-0-05.png 975w", "/static/b2f83baf0bc407f6ffe521af9f133465/75609/GoogleForms-0-05.png 994w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`2- Publier le Google Sheet sur le web`}</h2>
    <p><em parentName="p">{`Si vous avez un Google Sheet qui n'est `}<strong parentName="em">{`pas`}</strong>{` le résultat d'un Google Form alors vous pouvez commencer à cette étape.`}</em></p>
    <br />
    <p>{`Le menu `}<strong parentName="p">{`Fichier/Publier sur le web`}</strong>{` ... fait apparaitre la fenêtre de réglages suivante:`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "549px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100.61349693251533%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACbElEQVQ4y51UyW4TQRCdf+HIR3DjyofwE3wAR04IcQIJOMABQYSSICTEIYoUDAqOseNlFtuzevbp2R9VbY0hDkQ2LT1XL6PnV91VT0nTFHEcI0kSZFkmwXPeE0KgKArk+XV0++uYb6DUdY1+/0JC1w1omobpdIperyeJdx1MzENp21Yyp1Kd2IDVZQQ+33Xwt0pZllgsl7AsG7bjwLQsWLYt49K04PsBgjBEEATwvJWMIa1Xq/Wc4ROqqlorZMLJdCbTVTUdmk6gOKG0GYYxl+fD0Uiez1QV4/EYE83CSI9wPvYwnQfgq5OEV0U3f01jr5SbpkVK9+lFOfrDGWLRIskbNE0j09ANAyalzmtWweD5GrVEt7cmrBsEiYCfFQjzCgGxZ6JCVVbIuWxEvkXyb0hCliqiFMFUR6wt4Ko6uAAYOj2MtljK8tk1dSWnlE7ev8Tp0Rt8+fAKy8+fgIsRmtEEGFwCE3VzP7tAmbx4htsP7uDu8/u49egenhw+BR4+RnlwhNbx0KYZ9hlKqWoYv3uN8ccDDA/fIjg+BrZIdlW3fhQuFkGXmpSkpqauqZFVLQQ9UEYoq2Y/wjCtMDB8nA0N9C4XOPup49twjq8DHSfnMxhOvFc9Kg19mFCZhEmOiEomJHDkvYTUVnWz36PI/qDitCxTwqS+dqmnieK/ukXpmAUVsLfyyQgihFEk5ysC79+kaPsPlW5RkFlGUQw23IjMlUl53bnITf17RSH//DBHOFW/I3B9WI5N6lawbUfamOO60to4ck+7nke2ZkqwY19TyD9ZKRDniVTJZvunrct58dvq2e66M+7fbcJf8U0IiCziVggAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "publication sur le web-1",
          "title": "publication sur le web-1",
          "src": "/static/a3c01ff349d59beb5ff552159bf4a0c7/928ea/GoogleForms-1-05.png",
          "srcSet": ["/static/a3c01ff349d59beb5ff552159bf4a0c7/222b7/GoogleForms-1-05.png 163w", "/static/a3c01ff349d59beb5ff552159bf4a0c7/ff46a/GoogleForms-1-05.png 325w", "/static/a3c01ff349d59beb5ff552159bf4a0c7/928ea/GoogleForms-1-05.png 549w"],
          "sizes": "(max-width: 549px) 100vw, 549px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Vérifier que les options sont correctement positionnées et cliquer `}<strong parentName="p">{`Publier`}</strong>{`.  `}</p>
    <br />
    <br />
    <p>{`Il reste à cliquer sur l'icône verte `}<strong parentName="p">{`Partager`}</strong>{` en haut à droite. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "646px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.49079754601227%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABgElEQVQoz21Ry04cMRCc//+NgIZwIQoKlygXDgtRgsiVjZQDm8DOjOfhefnZXWl7FxQpWFOq6ranXe4ujKNyWKiMMZREXDIfQET/6cQHjRLA677EZ5HoRPSm+NUD774CZl2w31fo+x6D1hjHCcMwoOv6nMt5iRPatkVdN3JmxMvyPsA5rwvrKdS9DcuyBj1OYRznME5zmOYl8yxsnQ/GumCPMMaGVZByMVKGFCRx+FRA/GpxVDcd2l5DtT3abkCtOuwrlbX8B2P9AUkLbNLGYRVejWMnOSZWhTjBn+cK4kSeozOnp4gTuYshvUEIAWLjwJL3zsNHgk9PNTbFnDTLV+yrGnf3P7Db7fDwsMXj4w7b7c/cp1QwFYlSLCE4h7iukCYD0mPefAOuPgMfPgHXG4b0sVjE9lMzQI8z9HEIjVKoqgpKtXkYXYLklVyij4PgWiFefUH9e8BzY7g9uQRf33BhPKOZCDIccUTZ1QveXP/s0e13uPNL2NML+PcfwWLgL5unX6mgftS4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "publication sur le web-2",
          "title": "publication sur le web-2",
          "src": "/static/5ed9d9f50d6c58bcbbcba54a2c3da3f1/27524/GoogleForms-2-05.png",
          "srcSet": ["/static/5ed9d9f50d6c58bcbbcba54a2c3da3f1/222b7/GoogleForms-2-05.png 163w", "/static/5ed9d9f50d6c58bcbbcba54a2c3da3f1/ff46a/GoogleForms-2-05.png 325w", "/static/5ed9d9f50d6c58bcbbcba54a2c3da3f1/27524/GoogleForms-2-05.png 646w"],
          "sizes": "(max-width: 646px) 100vw, 646px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Demander à `}<strong parentName="p">{`copier le lien`}</strong>{` en bas. C'est lui qu'il faut déposer dans le champs ci dessous pour obtenir en échange l'adresse du rapport visuel. `}</p>
    <h2>{`3- Consulter le rapport automatique`}</h2>
    <br />
    <Checkurl mdxType="Checkurl" /> 
    <br />
    <p>{`Il faut généralement plusieurs secondes pour créer le rapport car il charge les codes postaux des 35k communes, ne désespérez pas devant la fenêtre vide du navigateur. Il se mettra automatiquement à jour en fonction des nouvelles réponses.`}</p>
    <br />
    <p>{`En cas d'erreur le message d'erreur est pour le moment très sibyllin: `}<em parentName="p">{`lien invalide`}</em>{`. Vous avez du vous trompez quelque part la forme du lien n'est pas reconnu.`}</p>
    <p>{`Si vous voulez vous faire une idée avant d'essayer, voici quelques exemples déjà traités:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://perceptible.fr/sandbox/forms/run.html?key=1aJcGeFBWmTA7cTTRtNnQzY9FAwRooJCbR6lYXerfr3E" target="_blank">sondage d'entreprises</a>
      </li>
      <li parentName="ul">
        <a href="https://perceptible.fr/sandbox/forms/run.html?key=1ucjP2FJlxMjOONtWmxPC4vFOlnld4nj6RaCHb1rY11Q" target="_blank">dataviz meetup Toulouse</a>
      </li>
    </ul>
    <br />
    <h3>{`Comment ça marche ?`}</h3>
    <p>{`Il s'agit de quelques heuristiques qui cherchent à deviner le type de chaque colonne (réponses). En fonction des valeurs et du nombre de catégories, l'algorithme décide de représenter les valeurs en :`}</p>
    <ul>
      <li parentName="ul">{`histogramme : si valeurs numériques à > 90 %    `}</li>
      <li parentName="ul">{`barres : si valeurs non numériques et en nombre < réponses /4`}</li>
      <li parentName="ul">{`camembert : si nombre catégories <=4`}</li>
      <li parentName="ul">{`nombre : si une seule catégorie`}</li>
      <li parentName="ul">{`composite : si valeurs non numériques et en nombre > réponses * 0.9`}</li>
      <li parentName="ul">{`carte : si tous les nombres sont entre 4 et 5 chiffres et que plus de 80% sont des Codes Postaux existants`}</li>
    </ul>
    <p>{`Si le type de la colonne n'est pas devinée alors elle n'apparait pas dans le rapport.`}</p>
    <h3>{`Alerte News`}</h3>
    <p>{`Il est aussi possible de coller le lien d'une table `}<a parentName="p" {...{
        "href": "https://medium.com/@alain.roan/notion-so-un-cerveau-num%C3%A9rique-%C3%A0-plusieurs-87a09a7e6aa5"
      }}>{`Notion`}</a>{` à condition qu'elle soit publique.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      